<template>
  <div class="rebate clearfix">
    <div class="left clearfix">
      <li class="container">
        <p>{{ $t('home.RebateAccountNumber') }}</p>
        <el-form class="account">
          <AccountNumber ref="accountNumber" @accountCallback="init"></AccountNumber>
        </el-form>
        <div class="container_amount">
          <p v-if="hasTransferPermission">
            {{ currAccountCurrency | currencySymbol }}
            {{ totalBalance | currency('') }}
          </p>
          <p v-else>***</p>
          <p>{{ $t('home.totalCommission') }}</p>
        </div>
        <div class="container_button">
          <div class="form_button">
            <el-button
              :disabled="!hasTransferPermission"
              to=""
              @click.native="applyRebate()"
              class="el-button white_button"
              data-testid="applyRebate"
            >
              {{ $t('home.APPLY_FOR_A_REBATE') }}
            </el-button>
          </div>
        </div>
      </li>
    </div>
    <div class="middle">
      <img src="@/assets/uploads/middle.png" />
    </div>
    <div class="right clearfix">
      <li class="container">
        <p>{{ $t('home.RebateAccountNumber') }}</p>
        <p id="showMessage">{{ accountID }}</p>
        <div class="container_amount">
          <p v-textOverflow v-if="hasTransferPermission">
            {{ currAccountCurrency | currencySymbol }}
            {{ availableBalance | currency('') }}
          </p>
          <p v-else>***</p>
          <p>{{ $t('home.availableBalance') }}</p>
        </div>
        <div class="container_button">
          <div class="form_button right_form_button">
            <el-button
              :disabled="!hasTransferPermission"
              to=""
              @click.native="goToRebateTransfer()"
              class="el-button purple_button"
              data-testid="TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT"
            >
              {{ $t('home.TRANSFER_REBATE_INTO_YOUR_TRADING_ACCOUNT') }}
            </el-button>

            <el-button
              :disabled="!hasTransferPermission"
              to=""
              @click.native="goToRebateWithdraw()"
              class="el-button white_button"
              data-testid="WITHDRAW_REBATE"
            >
              {{ $t('home.WITHDRAW_REBATE') }}
            </el-button>
          </div>
        </div>
      </li>
    </div>
    <!-- apply for rebate 弹窗 -->
    <vDialog :show.sync="dialogVisible">
      <template v-slot:header>
        <img src="@/assets/uploads/close.png" alt class="close" @click="dialogVisible = false" data-testid="close" />
      </template>
      <img src="@/assets/uploads/dialog_true.png" alt class="true" />

      <p>{{ $tc('home.applyRebateSuccessTip', 0) }}</p>
      <p v-html="$tc('home.applyRebateSuccessTip', 1, { amount: appliedCommission, account: accountID })"></p>
      <p>{{ $tc('home.applyRebateSuccessTip', 2) }}</p>
    </vDialog>
  </div>
</template>

<script>
import AccountNumber from '@/components/form/AccountNumber';
import vDialog from '@/components/vDialog';
import { apiQueryAvailableBalance, apiApplyCommission, apiQueryRebatesBlackList } from '@/resource';
import blacklist from '@/mixins/blacklist';

export default {
  components: { AccountNumber, vDialog },
  mixins: [blacklist],
  props: { userID: '' },
  data() {
    return {
      dialogVisible: false,
      appliedCommission: 0,
      availableBalance: 0
    };
  },
  computed: {
    accountID() {
      return this.$store.state.behavior.AID;
    },
    currAccountCurrency() {
      return this.$store.state.behavior.CUR;
    },
    totalBalance: {
      get() {
        return this.hasTransferPermission ? this.$store.state.behavior.balance : 0;
      },
      set(value) {
        return this.$store.commit('behavior/setBalance', value);
      }
    }
  },
  methods: {
    // 获取 可用余额
    queryAvailableBalance() {
      if (!this.accountID) return;
      apiQueryAvailableBalance({
        qUserId: this.userID,
        qAccount: this.accountID
      }).then(resp => {
        if (resp.data.code === 0) {
          this.hasTransferPermission
            ? (this.availableBalance = resp.data.data.availableBalance)
            : (this.availableBalance = 0);
        }
      }, this.$handleError);
    },
    // 申请佣金
    async applyRebate() {
      if (!this.accountID) return;
      let result = await apiQueryRebatesBlackList();
      if ((result.data.data.list.filter(item => item.login === this.accountID)[0] || []).isCommissionProhibit) {
        this.$message({
          type: 'error',
          message: this.$t('home.blacklist')
        });
        return;
      }
      apiApplyCommission({
        qAccount: this.accountID,
        qUserId: this.userID
      }).then(resp => {
        if (resp.data.code === 0) {
          this.appliedCommission = this.$options.filters.currency(this.totalBalance, '');
          this.dialogVisible = true;
          this.$refs.accountNumber.rebatesList.map((item, idx) => {
            if (item.login === this.accountID) item.commission = 0;
          });
          this.totalBalance = 0;
          //save for passing new commission among different pages
          this.bus.$emit('updateRebateHistory', true);
        }
      }, this.$handleError);
    },
    // 内容初始化
    init() {
      this.queryAvailableBalance();
      this.$emit('accountIdAndCurrency', this.accountID, this.currAccountCurrency);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/rebate.scss';
</style>
